<template>
  <div>
<!--    判断当前是否为企业维信浏览器-->
    <ToastIcon v-if="!isEnterpriseWeChat"></ToastIcon>
    <el-row :gutter="20" v-else>
      <el-col :span="8" v-for="(list,index) in cards" :key="index">
        <div class="grid-content" @click="openUrl(list.url)">
          <div class="grid-img"><img :src="list.image"></div>
          <div class="grid-title">{{list.title}}</div>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import {getUserInfo} from "@/service/document_api";
import ToastIcon from "@/components/ToastIcon";
import {isEnterpriseWeChat} from "@/utils/isEnterpriseWeChat";

export default {
name: "MyYunicu.vue",
  components: {ToastIcon},
  data(){
  return{
    isEnterpriseWeChat:isEnterpriseWeChat(),
    cards:[
      {
        id:1,
        url:'https://tool-h5.yunicu.com/#/communityData',
        image:'https://img0.baidu.com/it/u=1705694933,4002952892&fm=253&fmt=auto&app=138&f=JPEG?w=500&h=281',
        title:'社区数据分析'
      },
      {
        id:2,
        url:'https://tool-h5.yunicu.com/#/communityContentData',
        image:'https://img0.baidu.com/it/u=1705694933,4002952892&fm=253&fmt=auto&app=138&f=JPEG?w=500&h=281',
        title:'社区内容数据分析'
      },
      // {
      //   id:3,
      //   url:'https://tool-h5.yunicu.com/v2/#/MagazineAnalysis',
      //   image:'https://img0.baidu.com/it/u=1705694933,4002952892&fm=253&fmt=auto&app=138&f=JPEG?w=500&h=281',
      //   title:'医学杂志数据分析'
      // },{
      //   id:4,
      //   url:'https://tool-h5.yunicu.com/v2/#/DocumentAnalysis',
      //   image:'https://img0.baidu.com/it/u=1705694933,4002952892&fm=253&fmt=auto&app=138&f=JPEG?w=500&h=281',
      //   title:'文献指南数据分析'
      // },{
      //   id:5,
      //   url:'https://tool-h5.yunicu.com/v2/#/ExamAnalysis',
      //   image:'https://img0.baidu.com/it/u=1705694933,4002952892&fm=253&fmt=auto&app=138&f=JPEG?w=500&h=281',
      //   title:'职称考试数据分析'
      // },
      {
        id:6,
        url:'https://tool-h5.yunicu.com/#/televiseLive',
        image:'https://img0.baidu.com/it/u=1705694933,4002952892&fm=253&fmt=auto&app=138&f=JPEG?w=500&h=281',
        title:'直播小会分析'
      },
      {
        id:7,
        url:'https://tool-h5.yunicu.com/#/boardRoom',
        image:'https://img0.baidu.com/it/u=1705694933,4002952892&fm=253&fmt=auto&app=138&f=JPEG?w=500&h=281',
        title:'直播大会分析'
      },
      {
        id:8,
        url:'https://tool-h5.yunicu.com/#/modifyViewers',
        image:'https://img0.baidu.com/it/u=1705694933,4002952892&fm=253&fmt=auto&app=138&f=JPEG?w=500&h=281',
        title:'直播工具'
      },{
        id:9,
        url:'https://tool-h5.yunicu.com/#/registerCollect',
        image:'https://img0.baidu.com/it/u=1705694933,4002952892&fm=253&fmt=auto&app=138&f=JPEG?w=500&h=281',
        title:'注册会员分析'
      },
    ],
    user:null,
  }
  },
  methods:{
    //打开链接
    openUrl(url){
      window.location.href = url
    },

    // 获取页面路径的code参数
    getUrlParam(name) { // 获取URL指定参数

      var reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)') // 构造一个含有目标参数的正则表达式对象

      var r = window.location.search.substr(1).match(reg) // 匹配目标参数

      this.code = unescape(r[2])

      if (r != null){
        return unescape(r[2])
      }else {
        return null
      }
    },

  //  通过向接口发送code获取用户的id
    async getUserId() {
      const response = await getUserInfo(this.getUrlParam('code'))
      if (response.code === 0) {
        // alert(JSON.stringify(response.data))
        //  向本地存储用户的id
        localStorage.setItem('EnterpriseWeChat', JSON.stringify(response.data))
      }else {
        this.$message.warning(response.message)
      }
    }
  },
  created() {
  //获取链接地址的code
    if(this.isEnterpriseWeChat){
      this.getUserId()
    }
  },
}
</script>

<style scoped lang="scss">
.el-row {
  margin-bottom: 20px;
&:last-child {
   margin-bottom: 0;
 }
}
.el-col {
  border-radius: 4px;
}
.bg-purple-dark {
  background: #99a9bf;
}
.bg-purple {
  background: #d3dce6;
}
.bg-purple-light {
  background: #e5e9f2;
}
.grid-content {
  //margin: 20px;
  margin-top: 30px;
  font-size: 12px;
  color: #333333;
  //min-height: 150px;
  text-align: center;
  .grid-img{
    display: flex;
    justify-content: center;
  }
  .grid-title{
    margin-top: 10px;
  }
  img{
    width: 70px;
    height: 70px;
    border-radius: 5px;
  }
}
</style>
